import { User } from "@microsoft/microsoft-graph-types";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import { getRequestMsGraph } from "../../services/APIService";
import { RootState } from "../../store/store";
export interface UserPicture {
  userId?: string;
  photo: any;
}
export interface msGraphState {
  user: any;
  groupMembers: User[];
  groupMembersPhotos: UserPicture[];
  groupId: string;
  status: "idle" | "loading" | "failed";
}
const initialState: msGraphState = {
  user: null,
  groupMembers: [],
  groupMembersPhotos: [],
  groupId: "",
  status: "idle",
};
export const getGroupMembersAsync: any = createAsyncThunk(
  "user/getGroupMembers",
  async (id: string) => {
    const response = await getRequestMsGraph(
      `groups/${id}/members`
    );
    return response.json();
  }
);

export const getUserPhotoAsync: any = createAsyncThunk(
  "user/getGroupMembersPhotos",
  async (id?: string) => {
    const response = await getRequestMsGraph(`users/${id}/photo/$value`);
    const image =  await response.blob();
    const win = window.URL || window.webkitURL;
    return win.createObjectURL(image);
  
    
  }
);
const msGraphSlice = createSlice({
  name: "msGraph",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getGroupMembersAsync.fulfilled, (state, action) => {
        state.status = "idle";
        state.groupMembers = (action.payload.value).filter((user: any)=> user.jobTitle);
      })
      .addCase(getUserPhotoAsync.fulfilled, (state, action) => {
        if (
          !state.groupMembersPhotos.find((user) => user.userId === action.meta.arg)
        ) {
          state.groupMembersPhotos.push({
            userId: action.meta.arg,
            photo: action.payload,
          });
        }

        state.status = "idle";
      })
      .addCase(getUserPhotoAsync.rejected, (state, action) => {
        state.status = "idle";
      });
  },
});

export const selectUserPhotos = (state: RootState): UserPicture[] =>
 ( state.msGraph as msGraphState ).groupMembersPhotos;
export const selectGroupMembers = (state: RootState): any[] =>
( state.msGraph as msGraphState ).groupMembers;

export default msGraphSlice.reducer;

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { routes as adminRoutes } from "./app-admin/config/routes";

// import ProjectsRoutes from "EDProjects/ProjectsRoutes";
import React, { FC, ReactElement, useEffect } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
  useLocation,
} from "react-router-dom";
import Layout from "./components/layout/Layout";
import { routes as localRoutes } from "./config";
import { msalConfig } from "./config/authConfig";
// import {
//   getGroupMembersAsync,
//   getUserPhotoAsync,
//   selectGroupMembers,
//   selectUserPhotos,
// } from "./features/msGraph/msGraphSlice";
import { userState, setCurrentInstance } from "./features/user/userSlice";
import "./i18n";
import RouteItem from "./models/RouteItem.model";
import AuthenticationService from "./services/AuthenticationService";
import { useAppSelector, useAppDispatch } from "./store/hooks";
import { store } from "./store/store";
import ThemeContainer from "./theme/ThemeContainer";
import projectRoutes from "./app-projects/config/routes";
import { getGroupMembersAsync, getUserPhotoAsync, selectGroupMembers } from "./features/msGraph/msGraphSlice";
import { useDispatch } from "react-redux";
import { User } from "@microsoft/microsoft-graph-types";
// import { User } from "@microsoft/microsoft-graph-types";
// import { useDispatch } from "react-redux";
const routes = [...localRoutes, ...adminRoutes, ...projectRoutes];

const msalInstance = new PublicClientApplication(msalConfig);

const DefaultComponent: FC<unknown> = (): ReactElement => (
  <div>No Component</div>
);

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function App(): JSX.Element {
  const dispatch = useDispatch();
  const storeState = store.getState();
  const userSettings = storeState.user as userState;
  const routerBasename = `/${userSettings.tenant}/${userSettings.instance}`;
  const members: User[] = useAppSelector(selectGroupMembers);

  const query = useQuery();

  const instance = query.get("instance");

  if (!!instance) {
    localStorage.setItem("instance", instance);
  }
  const storedInstance = localStorage.getItem("instance");
  const instanceName = !!storedInstance ? storedInstance : "frontend";
  localStorage.setItem("instance", instanceName);

  useEffect(() => {
    dispatch(setCurrentInstance(instanceName));
  });


  useEffect(() => {
    if (members.length > 0) return;
    dispatch(getGroupMembersAsync("14c0a58b-41a5-46e7-b1f5-3e8bed69d476"));//TODO: Dynamic get users
  }, []);
  useEffect(() => {
    members.forEach((user: User) => dispatch(getUserPhotoAsync(user.id)));
  }, [members]);

  return (
    <MsalProvider instance={msalInstance}>
      <AuthenticationService>
        <ThemeContainer>
          {/* <Router basename={routerBasename}> */}
          <Switch>
            <Redirect exact from="/" to="/home" />
            <Layout>
              {routes.map((route: RouteItem) => (
                <Route
                  key={`${route.key}`}
                  path={`${route.path}`}
                  component={route.component || DefaultComponent}
                  exact
                />
              ))}
            </Layout>
          </Switch>
          {/* </Router> */}
        </ThemeContainer>
      </AuthenticationService>
    </MsalProvider>
  );
}

export default App;

import { Stack } from "@mui/material";
import React, { CSSProperties, useEffect } from "react";
import { Handle, HandleType, Position } from "react-flow-renderer";
import {
  selectCurrentElement,
  selectWorkflowCurrentOrientation,
  workflowOrientation,
} from "../../../features/workflow/workflowSlice";
import { useAppSelector } from "../../../store/hooks";
import "./customnode.scss";
import NodeInfo from "./nodeinfo/NodeInfo";
import NodeTitle from "./nodetitle/NodeTitle";

export interface CustomNodeProps {
  data: any;
  isConnectable: any;
}

export interface NodeHandle {
  handleId: string;
  type: HandleType;
  style?: CSSProperties;
}

export const getCustomNode = (handles: NodeHandle[]): any => {
  return WrappedComponent;
  function WrappedComponent(args: CustomNodeProps) {
    const workflowOrientation = useAppSelector(
      selectWorkflowCurrentOrientation
    );
    const currentNode = useAppSelector(selectCurrentElement);
    return (
      <div className="custom-workflow-node-root">
        {handles.map((handle: NodeHandle, index: number) => (
          <Handle
            key={index}
            type={handle.type}
            position={getHandlePosition(handle.type, workflowOrientation)}
            style={getHandleOffset(handles, handle, workflowOrientation)}
            id={`${index}`}
          />
        ))}
        <Stack
          className={`node-metadata ${
            currentNode && currentNode.id.toString() === args.data.id.toString() && "outlined"
          }`}
          spacing={2}>
          <NodeTitle data={args.data} />
          <NodeInfo data={args.data} />
        </Stack>
      </div>
    );
  }
};

const getHandlePosition = (
  type: HandleType,
  orientation: workflowOrientation
): Position => {
  if (orientation === "vertical") {
    ////[ TYPE, ORIENTATION]
    if (type === "source") {
      return Position.Bottom; //[source, vertical]
    } else {
      return Position.Top; //[target, vertical]
    }
  } else {
    if (type === "source") {
      return Position.Right; //[source,horizontal]
    } else {
      return Position.Left; //[target,horizontal]
    }
  }
};

// Method to generate position of the handle on the node
const getHandleOffset = (
  handles: NodeHandle[],
  currentHandle: NodeHandle,
  orientation: workflowOrientation
) => {
  const matchingTypeHandles = handles.filter(
    (handle: NodeHandle) => handle.type === currentHandle.type
  );
  const currentIndex =
    matchingTypeHandles.findIndex(
      (handle: NodeHandle) => handle.handleId === currentHandle.handleId
    ) + 1;
  const length = matchingTypeHandles.length;
  const verticalOffset = 20;
  const horizontalOffset = 10;

  const baseStyling = { background: "#555", width: "9px", height: "9px" };
  //  Leave the handles position unchanged(ie in the center):
  // If there is only one handle of a type.
  // Or if there is an odd number of handles of a type and the index of the current Handle is
  // the middles of the array.
  if (
    matchingTypeHandles.length === 1 ||
    (length % 2 !== 0 && length / 2 + 0.5 === currentIndex)
  ) {
    return baseStyling;
  }

  if (orientation === "vertical") {
    // if the orientation is vertical then handles placed on top and bottom side of node.
    return currentIndex <= length / 2
      ? { ...baseStyling, left: currentIndex * verticalOffset }
      : {
          ...baseStyling,
          right: -(Math.ceil(length / 2) - currentIndex) * verticalOffset,
          left: "auto",
        };
  } else {
    //if the orientation is horizontal then handles placed on right and left side  of node.
    return currentIndex < length / 2
      ? { ...baseStyling, top: currentIndex * horizontalOffset }
      : {
          ...baseStyling,
          bottom: -(Math.ceil(length / 2) - currentIndex) * horizontalOffset,
          top: "auto",
        };
  }
};

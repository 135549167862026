import { Box, Grid, TextField, Typography } from "@mui/material";
import { postFields, putFields } from "../../../services/APIService";
import React, { FC, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import FieldItem from "../../../models/FieldTypes/FieldItem.model";
import scopeTypes, {
  scopeTypeDisplayNameFromId,
  scopeTypeIdFromDisplayName,
} from "../../../models/FieldTypes/ScopeTypes.enum";
import ILocation from "../../../models/ILocation.model";
import EdisonFormSelect from "../../../app-shared/edison/formselect/EdisonFormSelect";
import defaultItem from "../../utils/defaultItem";
import removeWhitespaces from "../../utils/removeWhitespaces";
import AdminFormInput from "../AdminFormInput";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import "./formdesignerproperties.scss";
import {
  selectFormName,
  setFormName,
} from "../../features/livepreview/livepreviewSlice";

const defaultButton: FieldItem = defaultItem("ideas", {});

const FormDesignerPropperties: FC = () => {
  // HOOKS

  const history = useHistory();
  const { t } = useTranslation(["admin"]);
  const methods = useForm();
  const { handleSubmit, watch } = methods;
  const location: ILocation = useLocation();
  const dispatch = useAppDispatch();
  const formName = useAppSelector(selectFormName);
  //STATE
  const [isLoading, setLoading] = useState(false);

  const isEdit = !!location.state && !!location.state.item;

  const field: FieldItem = !!isEdit
    ? (location.state.item as FieldItem)
    : defaultButton;

  //leaving watchSelect for future use with select options
  // const watchSelect = watch(
  //   "scopeType",
  //   scopeTypeIdFromDisplayName(field.dataType)
  // );

  // const cancelOperation = () => {
  //   history.push({
  //     pathname: "/admin/fields",
  //     state: { title: t("fields") },
  //   });
  // };

  // const onSubmit = async (data: any) => {
  //   setLoading(true);

  //   const scopeType = scopeTypeDisplayNameFromId(data.scopeType);

  //   const result = {
  //     displayName: data.displayName,
  //     type: scopeType,
  //     isDeleted: false,
  //     name: removeWhitespaces(data.displayName),
  //     description: data.description,
  //   };
  //   !!isEdit
  //     ? await putFields({ ...result, id: field.id })
  //     : await postFields(result);
  //   setLoading(false);
  //   history.push({
  //     pathname: "/admin/forms",
  //     state: { title: t("forms") },
  //   });
  // };

  return (
    <Grid className="form-designer-properties">
      <TextField
        label={t("label")}
        size="small"
        className="no-label-input"
        value={formName}
        onChange={(e: any) => dispatch(setFormName(e.target.value))}
      />

      {/* <FormProvider {...methods}>
        <AdminFormInput
          name="displayName"
          label={t("label")}
          internalName={isEdit ? field.name : undefined}
          defaultValue={field.displayName}
          hasIDField={true}
          requiredMessage={t("labelRequiredMessage")}
          maxLength={255}
        />
        <EdisonFormSelect
          name="scopeType"
          label={t("scope")}
          isDisabled={!!isEdit}
          data={scopeTypes.map((scopeType) => ({
            id: scopeType.id,
            displayName: t(scopeType.displayName),
          }))}
          requiredMessage={t("scopeRequiredMessage")}
          defaultValue={
            scopeTypes.find(
              (scopeToFind) => field.dataType === scopeToFind.displayName
            )?.id
          }
        />
      </FormProvider> */}
    </Grid>
  );
};

export default FormDesignerPropperties;

import { Button, Stack } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import {
  removeWorkflowNode,
  selectCurrentElement,
} from "../../../../features/workflow/workflowSlice";
import { useAppSelector } from "../../../../store/hooks";
import EdisonTypography from "../../../../../app-shared/edison/typography/EdisonTypography";

const ConnectionOptions = () => {
  const { t } = useTranslation(["admin"]);
  const dispatch = useDispatch();
  const currentElement = useAppSelector(selectCurrentElement);
  return (
    <Stack>
      <EdisonTypography title={t("connectionOptions")} variant="t2" />
      <Button
        variant="outlined"
        draggable={true}
        style={{
          paddingTop: "1.1em",
          paddingBottom: "1.1em",
          transform: "translate3d(0, 0, 0)",
        }}
        onClick={() => {
          if (!currentElement) return;
          dispatch(removeWorkflowNode((currentElement as any)));// Definitely wrong
        }}>
        {t("deleteConnection")}
      </Button>
    </Stack>
  );
};

export default ConnectionOptions;

import { Grid } from "@mui/material";
import React, { FC, useEffect } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router-dom";
import ExpandableMenu from "../../components/expandableMenu/ExpandableMenu";
import Features from "../../components/features/Features";
import FormDesignerProperties from "../../components/formdesignerproperties/FormDesignerProperties";
import Layout from "../../components/layout/Layout";
import LivePreview from "../../components/livePreview/LivePreview";
import { section } from "../../config/constants";
import { getFieldsAsync } from "../../features/fields/fieldsSlice";
import removeWhitespaces from "../../utils/removeWhitespaces";
import {
  setIsEdit,
  selectFormData,
  selectFormName,
  resetLiveForm,
} from "../../features/livepreview/livepreviewSlice";
import {
  getTablesAsync,
  selectTableStatus,
} from "../../features/tables/tablesSlice";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { RootState } from "../../store/store";
import EdisonButton from "../../../app-shared/edison/button/EdisonButton";
import "./formdesigner.scss";
import FormModel from "../../../models/Form.model";
import {
  createFormAsync,
  getFormsStatus,
} from "../../features/forms/formsSlice";

const FormDesigner: FC = () => {
  const history = useHistory();
  const methods = useForm();
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["admin"]);
  const location: any = useLocation();
  const cancelOperation = async () => {
    await dispatch(resetLiveForm());
    history.push({
      pathname: "/admin/forms",
      state: { title: t("forms") },
    });
  };

  useEffect(() => {
    !location.state?.configuration
      ? dispatch(setIsEdit(location.state.isEdit))
      : dispatch(setIsEdit(true));
  }, [location]);

  const fieldsStatus = useAppSelector(
    (state: RootState) => state.fields.status
  );
  const tablesStatus = useAppSelector(selectTableStatus);
  const formStatus = useAppSelector(getFormsStatus);
  const formData = useAppSelector(selectFormData);
  const formName = useAppSelector(selectFormName);

  useEffect(() => {
    if (fieldsStatus === "idle") {
      dispatch(getFieldsAsync());
    }
    if (tablesStatus === "idle") {
      dispatch(getTablesAsync());
    }
  }, []);

  const onSubmit = async () => {
    const formResult: FormModel = {
      // modified: new Date(),
      name: removeWhitespaces(formName),
      displayName: formName,
      dataType: 1,
      isDeleted: false,
      // configuration: JSON.stringify(formData),
      containers: formData.map((tab, tabIndex) => {
        return {
          id: tabIndex,
          displayName: tab.title,
          type: "type",
          order: tabIndex,
          formId: 0,
          zones: tab.children?.map((row: any, rowIndex: number) => {
            return {
              id: rowIndex,
              displayName: row.title,
              order: rowIndex,
              containerId: tabIndex,
              columns: row.children.map((column: any, columnIndex: number) => {
                return {
                  id: columnIndex,
                  displayName: column.title,
                  order: columnIndex,
                  zoneId: rowIndex,
                  fields: column.children.map(
                    (component: any, componentIndex: number) => {
                      const { modified, ...rest } = component.component;

                      return {
                        id: componentIndex,
                        columnId: columnIndex,
                        fieldId: component.component.id,
                        order: componentIndex,
                        field: rest,
                      };
                    }
                  ),
                };
              }),
            };
          }),
        };
      }),
    };

    await dispatch(createFormAsync(formResult));

    history.push({
      pathname: "/admin/forms",
      state: { title: t("forms") },
    });
    dispatch(resetLiveForm());
  };

  return (
    <Layout>
      <DndProvider backend={HTML5Backend}>
        <FormProvider {...methods}>
          <form>
            <Grid className="form-designer-page-root" container spacing={3}>
              <Grid item xs={3} className="form-designer-page-root__item">
                <ExpandableMenu title={t("properties")}>
                  <FormDesignerProperties />
                </ExpandableMenu>
                <ExpandableMenu title={t("features")} sticky>
                  <Features
                    tabs={section.map(({ id, name }) => ({ id, label: name }))}
                  />
                </ExpandableMenu>
              </Grid>
              <Grid item xs={9} className="form-designer-page-root__item">
                <LivePreview form={null}></LivePreview>
              </Grid>
            </Grid>
            <div className="form-designer-button-container">
              <EdisonButton onClick={cancelOperation} name={t("cancel")} />
              <EdisonButton onClick={() => onSubmit()} name={t("save")} />
            </div>
          </form>
        </FormProvider>
      </DndProvider>
    </Layout>
  );
};
export default FormDesigner;

import { nanoid } from "nanoid";
import ArrowHeadType, { ConnectionLineType, Edge } from "react-flow-renderer";
import {
  getCustomNode,
  NodeHandle,
} from "../../../app-admin/components/workflowdesigner/customnode/CustomNode";

export const nodeWidth = 200;
export const nodeHeight = 42;

export const handleList: NodeHandle[] = [
  {
    handleId: nanoid(),
    type: "target",
  },
  { handleId: nanoid(), type: "source" },
];
export const stageReviewHandleList: NodeHandle[] = [
  {
    handleId: nanoid(),
    type: "target",
  },
  { handleId: nanoid(), type: "source" },
  { handleId: nanoid(), type: "source" },
  { handleId: nanoid(), type: "source" },
];
export const nodeTypes = {
  Stage: getCustomNode(handleList),
  StageReview: getCustomNode(handleList),
  Condition: getCustomNode(handleList),
};

export const defaultWorkflowConnection: Partial<Edge<any>> = {
  type: ConnectionLineType.SmoothStep,
  // MarkerType: ArrowHeadType, // TODO: Figure why we cannot add this.
  style: { strokeWidth: 3 },
};

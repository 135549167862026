import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { addEdge, Edge, isNode, Node } from "react-flow-renderer";
import reducerStatus from "../../../models/reducerStatus";
import Workflow from "../../../models/workflow/backend/Workflow.model";
import {
  getIndividualWorkflow,
  getWorkflows,
} from "../../../services/APIService";
import { RootState } from "../../store/store";

export type workflowOrientation = "horizontal" | "vertical";

export interface SelectedElementIndex {
  type: "edge" | "node";
  index: number;
}
export interface workflowState {
  workflowList: any[];
  status: reducerStatus;
  nodes: Node[];
  edges: Edge[];
  selectedElement: Node | Edge | null;
  selectedElementIndex: SelectedElementIndex | null;
  orientation: workflowOrientation;
  workflow: Workflow;
  isEdit: boolean;
  changeMade: boolean;
  displayEventsType: displayEventsType | null;
  retrievedSingleWorkflow: boolean;
}

export type displayEventsType = "OnStart" | "OnComplete";

const defaultWorkflow: Workflow = {
  name: "",
  type: "Projects",
};
const AddWorkflowMock: Workflow = {
  name: "AddWorkflow",
  type: "Projects",
};
const initialState: workflowState = {
  nodes: [],
  edges: [],
  selectedElement: null,
  selectedElementIndex: null,
  orientation: "vertical",
  workflow: defaultWorkflow,
  workflowList: [AddWorkflowMock],
  status: "idle",
  isEdit: false,
  changeMade: false,
  displayEventsType: null,
  retrievedSingleWorkflow: false,
};

export const getWorkflowsAsync = createAsyncThunk(
  "workflows/getWorkflows",
  async (_, { rejectWithValue }) => {
    const response = (await getWorkflows()) as any;
    if (!(response.status as number).toString().startsWith("2"))
      return rejectWithValue(response.data);
    return response.data;
  }
);

export const getIndividualWorkflowAsync = createAsyncThunk(
  "workflow/getSingleWorkflow",
  async (id: number, { rejectWithValue }) => {
    const response = (await getIndividualWorkflow(id)) as any;
    if (!(response.status as number).toString().startsWith("2"))
      return rejectWithValue(response.data);
    return response.data;
  }
);

export const workflowSlice = createSlice({
  name: "workflow",
  initialState,
  reducers: {
    addWorkflowEdge: (state, action: PayloadAction<any>) => {
      const update = addEdge(action.payload, state.edges);
      state.edges = update;
      state.changeMade = true;
    },
    addWorkflowNode: (state, action: PayloadAction<Node>) => {
      state.nodes = state.nodes.concat(action.payload);
      state.changeMade = true;
    },
    removeWorkflowNode: (state, action: PayloadAction<Node>) => {
      state.nodes = state.nodes.filter((node) => node.id !== action.payload.id);

      state.changeMade = true;
    },
    setSelectedElement: (state, action: PayloadAction<any>) => {
      if (action.payload === null) {
        state.selectedElementIndex = null;
        return;
      }
      state.selectedElementIndex = isNode(action.payload)
        ? {
            type: "node",
            index: state.nodes.findIndex(
              (element: Node | Edge) => element.id === action.payload.id
            ),
          }
        : {
            type: "edge",
            index: state.edges.findIndex(
              (element: Node | Edge) => element.id === action.payload.id
            ),
          };
    },

    updateElement: (state, action: PayloadAction<Node | Edge>) => {
      isNode(action.payload)
        ? (state.nodes = state.nodes.map((node) =>
            node.id === action.payload.id ? (action.payload as Node) : node
          ))
        : (state.edges = state.edges.map((edge) =>
            edge.id === action.payload.id ? (action.payload as Edge) : edge
          ));
      state.selectedElement = action.payload;
      state.changeMade = true;
    },
    setNodes: (state, action: PayloadAction<Node[]>) => {
      state.nodes = action.payload;
    },
    setEdges: (state, action: PayloadAction<Edge[]>) => {
      state.edges = action.payload;
    },
    setWorkflowOrientation: (
      state,
      action: PayloadAction<workflowOrientation>
    ) => {
      state.orientation = action.payload;
    },
    deleteNode: (state, action: PayloadAction<string>) => {
      state.nodes = state.nodes.filter((node) => node.id.toString() !== action.payload.toString());
      state.edges = state.edges.filter(
        (edge) =>
          edge.source !== action.payload.toString() ||
          edge.target !== action.payload.toString()
      );
      state.changeMade = true;
    },
    updateWorkflowName: (state, action: PayloadAction<string>) => {
      state.workflow.name = action.payload;
    },
    setIsEdit: (state, action: PayloadAction<boolean>) => {
      state.isEdit = action.payload;
    },
    setChangeMade: (state, action: PayloadAction<boolean>) => {
      state.changeMade = action.payload;
    },
    setWorkflowToDefault: (state) => {
      state.workflow = defaultWorkflow;
    },
    setDisplayEvent: (
      state,
      action: PayloadAction<displayEventsType | null>
    ) => {
      state.displayEventsType = action.payload;
    },
    updateWorkflowDescription: (state, action: PayloadAction<string>) => {
      state.workflow.description = action.payload;
    },
    setRetrievedSingleWorkflow: (state, action: PayloadAction<boolean>) => {
      state.retrievedSingleWorkflow = action.payload;
    },
    resetWorkflowSlice: (state, action: PayloadAction<boolean>) => {
      state.nodes = [];
      state.edges = [];
      state.selectedElement = null;
      state.selectedElementIndex = null;
      state.orientation = "vertical";
      state.workflow = defaultWorkflow;
      state.status = "idle";
      state.isEdit = false;
      state.changeMade = false;
      state.displayEventsType = null;
      state.retrievedSingleWorkflow = false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getWorkflowsAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getWorkflowsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getWorkflowsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        if (!action.payload) return;
        state.workflowList = action.payload;
        state.workflowList.unshift(AddWorkflowMock);
      })
      .addCase(getIndividualWorkflowAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getIndividualWorkflowAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getIndividualWorkflowAsync.fulfilled, (state, action) => {
        state.status = "idle";
        if (!action.payload) return;
        state.workflow = action.payload;
        state.retrievedSingleWorkflow = true;
        state.changeMade = false;
      });
  },
});
export const {
  addWorkflowEdge,
  addWorkflowNode,
  removeWorkflowNode,
  setSelectedElement,
  updateElement,
  setWorkflowOrientation,
  deleteNode,
  updateWorkflowName,
  setIsEdit,
  setWorkflowToDefault,
  setDisplayEvent,
  updateWorkflowDescription,
  setRetrievedSingleWorkflow,
  resetWorkflowSlice,
  setNodes,
  setEdges,
} = workflowSlice.actions;

export const selectWorkflowNodes = (state: RootState): Node[] =>
  (state.workflow as workflowState).nodes;

export const selectWorkflowEdges = (state: RootState): Edge[] =>
  (state.workflow as workflowState).edges;

export const selectCurrentElement = (state: RootState): Node | Edge | null => {
  const currentElementIndex = (state.workflow as workflowState)
    .selectedElementIndex;
  if (!currentElementIndex) return null;
  return currentElementIndex.type === "node"
    ? (state.workflow as workflowState).nodes[currentElementIndex.index]
    : (state.workflow as workflowState).edges[currentElementIndex.index];
};

export const selectWorkflowCurrentOrientation = (
  state: RootState
): workflowOrientation => (state.workflow as workflowState).orientation;

export const selectWorkflow = (state: RootState): Workflow =>
  (state.workflow as workflowState).workflow;

export const selectWorkflowList = (state: RootState): Workflow[] =>
  (state.workflow as workflowState).workflowList;

export const selectIsEdit = (state: RootState): boolean =>
  (state.workflow as workflowState).isEdit;

export const selectChangeMade = (state: RootState): boolean =>
  (state.workflow as workflowState).changeMade;

export const selectDisplayEvents = (
  state: RootState
): displayEventsType | null =>
  (state.workflow as workflowState).displayEventsType;

export const selectRetrievedSingleWorkflow = (state: RootState): boolean =>
  (state.workflow as workflowState).retrievedSingleWorkflow;

export default workflowSlice.reducer;

import { createSlice, nanoid, PayloadAction } from "@reduxjs/toolkit";
import { COLUMN, ROW, TAB, DEFAULTHEADER } from "../../config/constants";

import { ItemDNDType } from "../../../models/Livepreview/ItemDNDType.model";
import { RootState } from "../../store/store";
import { moveDNDObjectInArray, splitPath } from "../../utils/dndHelpers";

export interface livepreviewState {
  formData: ItemDNDType[];
  formName: string;
  formScope: string;
  isEdit: boolean;
  isVisibilityMode: boolean;
  currentTab: string;
  usedComponents: string[];
}

const getDefaultColumn = (id: string): ItemDNDType => ({
  type: COLUMN,
  title: "",
  id: id,
  children: [],
});

const getDefaultRow = (id: string): ItemDNDType => ({
  type: ROW,
  title: "",
  id: id,
  children: [getDefaultColumn(nanoid())],
});

const getDefaultTab = (id: string): ItemDNDType => ({
  type: TAB,
  title: "",
  id: id,
  children: [getDefaultRow(nanoid())],
});

const initialState: livepreviewState = {
  formName: "",
  formScope: "",
  formData: [
    {
      type: TAB,
      id: "tab0",
      title: "",
      children: [getDefaultRow(nanoid())],
    },
  ],
  usedComponents: [],
  currentTab: "tab0",
  isEdit: true,
  isVisibilityMode: false,
};

export const livepreviewSlice = createSlice({
  name: "livepreview",
  initialState,
  reducers: {
    resetLiveForm: (state) => {
      state.formData = initialState.formData;
      state.formName = initialState.formName;
      state.isEdit = initialState.isEdit;
      state.isVisibilityMode = initialState.isVisibilityMode;
      state.currentTab = initialState.currentTab;
      state.usedComponents = initialState.usedComponents;
      state.formScope = initialState.formScope;
    },
    setCurrentTab: (state, action: PayloadAction<string>) => {
      state.currentTab = action.payload;
    },
    setFormName: (state, action: PayloadAction<string>) => {
      state.formName = action.payload;
    },
    setCurrentTabTitle: (state, action: PayloadAction<string>) => {
      state.formData = state.formData.map((tab) =>
        tab.id === state.currentTab ? { ...tab, title: action.payload } : tab
      );
    },
    setRowTitle: (state, action: PayloadAction<any>) => {
      const splitItemPath = splitPath(action.payload.path);
      state.formData = state.formData.map((tab) =>
        tab.id === state.currentTab
          ? {
              ...tab,
              children: tab.children?.map((row, i) =>
                i.toString() === splitItemPath[0]
                  ? { ...row, title: action.payload.title }
                  : row
              ),
            }
          : tab
      );
    },
    setColumnTitle: (state, action: PayloadAction<any>) => {
      const splitItemPath = splitPath(action.payload.path);
      state.formData = state.formData.map((tab) =>
        tab.id === state.currentTab
          ? {
              ...tab,
              children: tab.children?.map((row, i) =>
                i.toString() == splitItemPath[0]
                  ? {
                      ...row,
                      children: row.children?.map((column, i) =>
                        i.toString() === splitItemPath[1]
                          ? { ...column, title: action.payload.title }
                          : column
                      ),
                    }
                  : row
              ),
            }
          : tab
      );
    },
    moveTabIndex: (state, action: PayloadAction<any>) => {
      state.formData = moveDNDObjectInArray(
        state.formData,
        action.payload.dragIndex,
        action.payload.hoverIndex
      );
    },
    addTab: (state) => {
      state.formData.push(getDefaultTab(nanoid()));
    },
    removeTab: (state, action: PayloadAction<string>) => {
      state.formData = state.formData.filter(
        (tab) => tab.id !== action.payload
      );
      state.currentTab = state.formData[0].id;
    },
    updateLayout: (state, action: PayloadAction<ItemDNDType[]>) => {
      state.formData = state.formData.map((tab) =>
        tab.id === state.currentTab ? { ...tab, children: action.payload } : tab
      );
    },
    addNewRow: (state) => {
      state.formData = state.formData.map((tab) =>
        tab.id === state.currentTab
          ? {
              ...tab,
              children: [...(tab.children || []), getDefaultRow(nanoid())],
            }
          : tab
      );
    },
    addNewColumn: (state, action: PayloadAction<any>) => {
      state.formData = state.formData.map((tab) =>
        tab.id === state.currentTab
          ? {
              ...tab,
              children: tab.children?.map((row, i) =>
                i == action.payload
                  ? {
                      ...row,
                      children: [
                        ...(row.children || []),
                        getDefaultColumn(nanoid()),
                      ],
                    }
                  : row
              ),
            }
          : tab
      );
    },
    removeRow: (state, action: PayloadAction<string>) => {
      const splitItemPath = splitPath(action.payload);
      state.formData = state.formData.map((tab) =>
        tab.id === state.currentTab
          ? {
              ...tab,
              children: tab.children?.filter(
                (row, i) => i.toString() !== splitItemPath[0]
              ),
            }
          : tab
      );
    },
    removeColumn: (state, action: PayloadAction<string>) => {
      const splitItemPath = splitPath(action.payload);
      state.formData = state.formData.map((tab) =>
        tab.id === state.currentTab
          ? {
              ...tab,
              children: tab.children?.map((row, i) =>
                i.toString() == splitItemPath[0]
                  ? {
                      ...row,
                      children: row.children?.filter(
                        (column, i) => i.toString() !== splitItemPath[1]
                      ),
                    }
                  : row
              ),
            }
          : tab
      );
    },
    removeComponent: (state, action: PayloadAction<any>) => {
      const splitItemPath = splitPath(action.payload.path);
      state.formData = state.formData.map((tab) =>
        tab.id === state.currentTab
          ? {
              ...tab,
              children: tab.children?.map((row, i) =>
                i.toString() == splitItemPath[0]
                  ? {
                      ...row,
                      children: row.children?.map((column, i) =>
                        i.toString() == splitItemPath[1]
                          ? {
                              ...column,
                              children: column.children?.filter(
                                (component, i) =>
                                  i.toString() !== splitItemPath[2]
                              ),
                            }
                          : column
                      ),
                    }
                  : row
              ),
            }
          : tab
      );
      state.usedComponents = state.usedComponents.filter(
        (componentID) => componentID !== action.payload.id
      );
    },

    setIsEdit: (state, action: PayloadAction<boolean>) => {
      state.isEdit = action.payload;
    },
  },
});

export const {
  resetLiveForm,
  setFormName,
  setCurrentTab,
  setCurrentTabTitle,
  setRowTitle,
  setColumnTitle,
  moveTabIndex,
  updateLayout,
  addTab,
  removeTab,
  addNewRow,
  addNewColumn,
  removeRow,
  removeColumn,
  removeComponent,
  setIsEdit,
} = livepreviewSlice.actions;

export const selectFormData = (state: RootState): ItemDNDType[] =>
  state.livepreview.formData;

export const selectFormName = (state: RootState): string =>
  state.livepreview.formName;

export const selectLayout = (state: RootState): ItemDNDType[] =>
  state.livepreview.formData.find(
    (tab: ItemDNDType) => tab.id === state.livepreview.currentTab
  )?.children || [];

export const selectCurrentTab = (state: RootState): ItemDNDType =>
  state.livepreview.formData.find(
    (tab: ItemDNDType) => tab.id === state.livepreview.currentTab
  ) || state.livepreview.formData[0];

export const selectFormDesignerIsEdit = (state: RootState): boolean =>
  state.livepreview.isEdit;

export default livepreviewSlice.reducer;

import { Stack } from "@mui/material";
import React, { FC, useEffect, useRef, useState } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ReactFlowProvider from "react-flow-renderer";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import Layout from "../../components/layout/Layout";
import RightWorkflowPanel from "../../components/workflowdesigner/rightpanel/RightWorkflowPanel";
import parseToFrontend from "../../components/workflowdesigner/utils/parseToFrontend";
import WorkflowOption from "../../components/workflowdesigner/workflowoption/WorkflowOption";
import WorkFlowSelect from "../../components/workflowdesigner/workflowselect/WorkFlowSelect";
import WorkFlowZone from "../../components/workflowdesigner/workflowzone/WorkFlowZone";
import {
  getFieldsAsync,
  selectAllNonDeletedFields,
} from "../../features/fields/fieldsSlice";
import { getFormsAsync } from "../../features/forms/formsSlice";
import {
  selectRetrievedSingleWorkflow,
  selectWorkflow,
  setEdges,
  setNodes,
  setRetrievedSingleWorkflow,
} from "../../features/workflow/workflowSlice";
import defaultWorkFlowNodeTypes from "../../../models/workflow/constants/WorkflowOptions.constant";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import colors from "../../theme/colors.module.scss";
import EdisonCard from "../../../app-shared/edison/card/EdisonCard";
import "./workflowdesigner.scss";

const WorkflowDesigner: FC = () => {
  const { t } = useTranslation(["admin"]);

  const history = useHistory();

  const reactFlowWrapper = useRef<any | null>(null);
  const [reactFlowInstance, setReactFlowInstance] = useState<any | null>(null);

  const dispatch = useAppDispatch();

  const retrievedSingleWorkflow = useAppSelector(selectRetrievedSingleWorkflow);
  const workflow = useAppSelector(selectWorkflow);
  const fields = useAppSelector(selectAllNonDeletedFields);

  const onLoad = (_reactFlowInstance: any) =>
    setReactFlowInstance(_reactFlowInstance);

  useEffect(() => {
    dispatch(getFormsAsync()); // needed to connect stage to form
    dispatch(getFieldsAsync()); // needed to render condition expressions
  }, []);

  useEffect(() => {
    if (retrievedSingleWorkflow) {
      const {nodes, edges}= parseToFrontend(workflow, fields);
      dispatch(setNodes(nodes))
      dispatch(setEdges(edges))
     // dispatch(setElements(parseToFrontend(workflow, fields))); /// Used to set the elements when a workflow is selected
      dispatch(setRetrievedSingleWorkflow(false));
    }
  }, [retrievedSingleWorkflow]);

  return (
    <Layout backgroundColor={colors.grey50} titleMarginBottom="0px">
      <DndProvider backend={HTML5Backend}>
        <Stack className="workflow-designer-page-root" direction="column">
          <Stack direction="row" spacing={1} className="top-bar">
            <Stack direction="row" spacing={1}>
              {defaultWorkFlowNodeTypes.map((option, index: number) => (
                <div key={index} className="option-container">
                  <WorkflowOption
                    key={index}
                    option={option}
                    reactFlowWrapper={reactFlowWrapper}
                    reactFlowInstance={reactFlowInstance}
                  />
                </div>
              ))}
            </Stack>

            <WorkFlowSelect />
          </Stack>

          <Stack direction="row" className="body" spacing={1}>
            <div className="work-zone-container">
              <EdisonCard padding="13px">
                <ReactFlowProvider>
                  <div className="work-zone" ref={reactFlowWrapper}>
                    <WorkFlowZone
                      onLoad={setReactFlowInstance}
                      reactFlowInstance={reactFlowInstance}
                      reactFlowWrapper={reactFlowWrapper}
                    />
                  </div>
                </ReactFlowProvider>
              </EdisonCard>
            </div>
            <EdisonCard padding="13px">
              <Stack className={`node-options-zone`}>
                <RightWorkflowPanel />
              </Stack>
            </EdisonCard>
          </Stack>
        </Stack>
      </DndProvider>
    </Layout>
  );
};

export default WorkflowDesigner;

import { Button, Stack, Typography } from "@mui/material";
import dagre from "dagre";
import { postWorkflows, putWorkflows } from "../../../../services/APIService";
import React, { FC, useCallback, useEffect } from "react";
import ReactFlow, {
  applyEdgeChanges,
  applyNodeChanges,
  Background,
  ConnectionLineType,
  Controls,
  Edge,
  isNode,
  MiniMap,
  Node,
  Position,
  updateEdge,
  useStore,
} from "react-flow-renderer";
import { useTranslation } from "react-i18next";
import { getAllForms } from "../../../features/forms/formsSlice";
import {
  addWorkflowEdge,
  addWorkflowNode,
  getIndividualWorkflowAsync,
  removeWorkflowNode,
  selectChangeMade,
  selectIsEdit,
  selectWorkflow,
  selectWorkflowEdges,
  selectWorkflowNodes,
  setDisplayEvent,
  setEdges,
  setNodes,
  setSelectedElement,
  setWorkflowOrientation,
} from "../../../features/workflow/workflowSlice";
import {
  nodeHeight,
  nodeTypes,
  nodeWidth,
} from "../../../../models/workflow/constants/Node.constant";
import { WorkflowNodeConfiguration } from "../../../../models/workflow/WorkflowNode.model";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import colors from "../../../theme/colors.module.scss";
import CustomButton from "../../adminbutton/CustomButton";
import CustomEdge from "../customedge/CustomEdge";
import { MarkerDefinition } from "../customedge/MarkerDefinitition";
import getNewNode from "../utils/getNewNode";
import parseToBackend from "../utils/parseToBackend";
import getValidConnection from "../utils/validateConnections";
import "./workflowzone.scss";

export interface WorkflowZoneProps {
  reactFlowInstance: any;
  onLoad: any;
  reactFlowWrapper: any;
}
let nodeIndex = 0;
export const getId = () => `dndnode_${nodeIndex++}`;
export const getNodeIndex = () => nodeIndex;

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const edgeTypes: any = {
  custom: CustomEdge,
};
const WorkFlowZone: FC<WorkflowZoneProps> = ({
  onLoad,
  reactFlowInstance,
  reactFlowWrapper,
}) => {
  const dispatch = useAppDispatch();
  const { t } = useTranslation(["admin"]);
  const workflow = useAppSelector(selectWorkflow);
  const forms = useAppSelector(getAllForms);
  const isEdit = useAppSelector(selectIsEdit);
  const changeMade = useAppSelector(selectChangeMade);
  const newNodes = useAppSelector(selectWorkflowNodes);
  const newEdges = useAppSelector(selectWorkflowEdges);

  const onConnect = (params: any) => {
    const connection = getValidConnection(newNodes, newEdges, params);
    if (connection) {
      dispatch(addWorkflowEdge(connection));
    }
  };
  const onDragOver = (event: any) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  };

  const onPaneClick = (event: any) => {
    dispatch(setSelectedElement(null));
  };

  const onNodeClick = (event: any, clickedNode: any) => {
    const selectedNode = newNodes.find(
      (element: any) => element.id === clickedNode.id
    );
    dispatch(setSelectedElement(selectedNode));
    dispatch(setDisplayEvent(null));
  };
  const onEdgeClick = (event: any, clickedEdge: any) => {
    const selectedNode = newEdges.find(
      (element: any) => element.id === clickedEdge.id
    );
    dispatch(setSelectedElement(selectedNode));
    dispatch(setDisplayEvent(null));
  };
  const onDrop = (event: any) => {
    event.preventDefault();
    if (reactFlowWrapper && reactFlowWrapper.current && reactFlowInstance) {
      const reactFlowBounds = reactFlowWrapper.current.getBoundingClientRect();
      const type = event.dataTransfer.getData("application/reactflow");
      const position = reactFlowInstance.project({
        x: event.clientX - reactFlowBounds.left,
        y: event.clientY - reactFlowBounds.top,
      });
      if (type) {
        const config: WorkflowNodeConfiguration = JSON.parse(type);
        const newNode = getNewNode(config, position);
        dispatch(addWorkflowNode(newNode));
      }
    }
  };

  const getLayoutedElements = (
    nodes: Node[],
    edges: Edge[],
    direction = "TB"
  ) => {
    const isHorizontal = direction === "LR";
    dagreGraph.setGraph({ rankdir: direction });
    nodes.forEach((node) => {
      dagreGraph.setNode(node.id, {
        width: node.width,
        height: node.height,
      });
    });

    edges.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    const layoutedNodes = nodes.map((node) => {
      const nodeWithPosition = dagreGraph.node(node.id);
      return {
        ...node,
        targetPosition: isHorizontal ? Position.Left : Position.Top,
        sourcePosition: isHorizontal ? Position.Right : Position.Bottom,
        position: {
          x: nodeWithPosition.x - nodeWithPosition.width / 2,
          y: nodeWithPosition.y - nodeWithPosition.height / 2,
        },
      };
    });
    dispatch(setNodes(layoutedNodes));
    isHorizontal
      ? dispatch(setWorkflowOrientation("horizontal"))
      : dispatch(setWorkflowOrientation("vertical"));
  };

  const onEdgeUpdate = (oldEdge: any, newConnection: any) => {
    dispatch(setEdges(updateEdge(oldEdge, newConnection, newEdges)));
  };

  const onNodesChange = (changes: any) => {
    dispatch(setNodes(applyNodeChanges(changes, newNodes)));
  };

  const onEdgesChange = useCallback(
    (changes: any) => {
      dispatch(setEdges(applyEdgeChanges(changes, newEdges)));
    },
    [newEdges]
  );
  return (
    <ReactFlow
      onInit={onLoad}
      nodes={newNodes}
      edges={newEdges}
      onConnect={onConnect}
      onDrop={onDrop}
      onDragOver={onDragOver}
      nodeTypes={nodeTypes}
      onPaneClick={onPaneClick}
      onNodeClick={onNodeClick}
      onEdgeClick={onEdgeClick}
      onEdgeUpdate={onEdgeUpdate}
      edgeTypes={edgeTypes}
      onNodesChange={onNodesChange}
      onEdgesChange={onEdgesChange}
      className="react-flow-root">
      <Stack direction="row" className="custom-controls">
        <Stack direction="row" spacing={2}>
          <CustomButton
            disabled={!changeMade}
            onClick={() => {
              if (forms.length > 0) {
                const parsed = parseToBackend(
                  newNodes,
                  newEdges,
                  workflow,
                  forms[0].id,
                  isEdit
                );
                isEdit ? putWorkflows(parsed) : postWorkflows(parsed);
              }
            }}
            innerText={t("save")}
          />

          <CustomButton
            innerText={t("restore")}
            disabled={!isEdit}
            onClick={() => {
              if (workflow.id) {
                dispatch(getIndividualWorkflowAsync(workflow.id));
              }
            }}
          />
        </Stack>

        <Stack direction="row" spacing={2}>
          <CustomButton
            innerText="horizontal"
            onClick={() => getLayoutedElements(newNodes, newEdges, "LR")}
          />
          <CustomButton
            innerText="vertical"
            onClick={() => getLayoutedElements(newNodes, newEdges, "TB")}
          />
        </Stack>
      </Stack>
      <Controls />
      <MiniMap />
      <Background color="#FFFFFF" />
      <MarkerDefinition id="edge-marker-base" color="grey" />
      <MarkerDefinition id="edge-marker-selected" color={colors.primaryColor} />
    </ReactFlow>
  );
};

export default WorkFlowZone;

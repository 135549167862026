import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getForms, postForm } from "../../../services/APIService";
import Item from "../../../models/Item.model";
import reducerStatus from "../../../models/reducerStatus";
import { RootState } from "../../store/store";
import formatModifiedDate from "../../utils/formatModifiedDate";

export interface formsState {
  value: any;
  forms: Item[];
  status: reducerStatus;
}

const initialState: formsState = {
  value: 0,
  forms: [],
  status: "idle",
};
export const getFormsAsync = createAsyncThunk(
  "forms/getForms",
  async (_, { rejectWithValue }) => {
    const response: any = await getForms();
    if (!(response.status as number).toString().startsWith("2"))
      return rejectWithValue(response.data);
    return response.data;
  }
);

export const createFormAsync = createAsyncThunk(
  "forms/createForm",
  async (data: unknown, { rejectWithValue }) => {
    const response: any = await postForm(data);
    if (!(response.status as number).toString().startsWith("2"))
      return rejectWithValue(response.data);
    return response.data;
  }
);

export const formsSlice = createSlice({
  name: "forms",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getFormsAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(getFormsAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(getFormsAsync.fulfilled, (state, action) => {
        state.status = "idle";
        if (!action.payload) return;
        state.forms = action.payload;
      })
      .addCase(createFormAsync.rejected, (state) => {
        state.status = "failed";
      })
      .addCase(createFormAsync.pending, (state) => {
        state.status = "loading";
      })
      .addCase(createFormAsync.fulfilled, (state, action) => {
        state.status = "idle";
      });
  },
});

export const getAllForms = (state: RootState): Item[] =>
  state.forms.forms.map((dataToMap: Item) => ({
    ...dataToMap,
    modified: formatModifiedDate(dataToMap.modified) as unknown as Date,
  }));

export const getFormsStatus = (state: RootState): reducerStatus =>
  state.forms.status;
export default formsSlice.reducer;

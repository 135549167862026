import { PublicClientApplication } from "@azure/msal-browser";
import { apiConfig, graphRequest, msalConfig } from "../config/authConfig";
import { callMsGraphGet, callMsGraphPost } from "./graphService";
import { execute } from "../utils/requestHandler";
import {
  fetchFields,
  fetchSingleElement,
  recycleFields,
  saveFields,
  updateFields,
} from "./fieldsService";

const msalInstance = new PublicClientApplication(msalConfig);
// We might need to move this out to requestHandler.js when we read user attributes from API

export function getForms(): Promise<unknown> {
  return execute(
    msalInstance,
    fetchFields,
    `${apiConfig.contentHubFormsEndpoint}`
  );
}

export function postForm(data: unknown): Promise<unknown> {
  return execute(
    msalInstance,
    saveFields,
    `${apiConfig.contentHubFormsEndpoint}`,
    data
  );
}

export function putForms(data: unknown): Promise<unknown> {
  return execute(
    msalInstance,
    updateFields,
    `${apiConfig.contentHubFormsEndpoint}`,
    data
  );
}

export function getAnnouncements(): Promise<any> {
  return execute(
    msalInstance,
    fetchFields,
    `${apiConfig.commonAnnouncementEndpoint}`
  );
}
export function getFields(): Promise<unknown> {
  return execute(
    msalInstance,
    fetchFields,
    `${apiConfig.contentHubFieldsEndpoint}`
  );
}

export function getIndividualField(id: number): Promise<unknown> {
  return execute(
    msalInstance,
    fetchSingleElement,
    `${apiConfig.contentHubFieldsEndpoint}`,
    null,
    id
  );
}
export function postFields(data: unknown): Promise<unknown> {
  return execute(
    msalInstance,
    saveFields,
    `${apiConfig.contentHubFieldsEndpoint}`,
    data
  );
}

export function deleteFields(id: number): Promise<unknown> {
  return execute(
    msalInstance,
    recycleFields,
    `${apiConfig.contentHubFieldsEndpoint}`,
    null,
    id
  );
}

export function putFields(data: unknown): Promise<unknown> {
  return execute(
    msalInstance,
    updateFields,
    `${apiConfig.contentHubFieldsEndpoint}`,
    data
  );
}

export function getTables(): Promise<unknown> {
  return execute(
    msalInstance,
    fetchFields,
    `${apiConfig.contentHubTablesEndpoint}`
  );
}

export function getIndividualTable(id: number): Promise<unknown> {
  return execute(
    msalInstance,
    fetchSingleElement,
    `${apiConfig.contentHubTablesEndpoint}`,
    null,
    id
  );
}
export function postTables(data: unknown): Promise<unknown> {
  return execute(
    msalInstance,
    saveFields,
    `${apiConfig.contentHubTablesEndpoint}`,
    data
  );
}

export function deleteTables(id: number): Promise<unknown> {
  return execute(
    msalInstance,
    recycleFields,
    `${apiConfig.contentHubTablesEndpoint}`,
    null,
    id
  );
}

export function putTables(data: unknown): Promise<unknown> {
  return execute(
    msalInstance,
    updateFields,
    `${apiConfig.contentHubTablesEndpoint}`,
    data
  );
}

export function getIndividualWorkflow(id: number): Promise<unknown> {
  return execute(
    msalInstance,
    fetchSingleElement,
    `${apiConfig.contentHubWorkflowEndpoint}`,
    null,
    id
  );
}
export function getWorkflows(): Promise<unknown> {
  return execute(
    msalInstance,
    fetchFields,
    `${apiConfig.contentHubWorkflowEndpoint}`
  );
}

export function postWorkflows(data: unknown): Promise<unknown> {
  return execute(
    msalInstance,
    saveFields,
    `${apiConfig.contentHubWorkflowEndpoint}`,
    data
  );
}

export function putWorkflows(data: unknown): Promise<unknown> {
  return execute(
    msalInstance,
    updateFields,
    `${apiConfig.contentHubWorkflowEndpoint}`,
    data
  );
}

export function getProjects(): Promise<unknown> {
  return execute(msalInstance, fetchFields, `${apiConfig.projectsEndpoint}`);
}

export function getIndividualProject(id: number): Promise<unknown> {
  return execute(
    msalInstance,
    fetchSingleElement,
    `${apiConfig.projectsEndpoint}`,
    null,
    id
  );
}
export function postProject(data: unknown): Promise<unknown> {
  return execute(
    msalInstance,
    saveFields,
    `${apiConfig.projectsEndpoint}`,
    data
  );
}

export function deleteProject(id: number): Promise<unknown> {
  return execute(
    msalInstance,
    recycleFields,
    `${apiConfig.projectsEndpoint}`,
    null,
    id
  );
}

export function putProject(data: unknown): Promise<unknown> {
  return execute(
    msalInstance,
    updateFields,
    `${apiConfig.projectsEndpoint}`,
    data
  );
}

export function getProjectTypes(): Promise<unknown> {
  return execute(
    msalInstance,
    fetchFields,
    `${apiConfig.projectTypesEndpoint}`
  );
}

export function getIndividualProjectType(id: number): Promise<unknown> {
  return execute(
    msalInstance,
    fetchSingleElement,
    `${apiConfig.projectTypesEndpoint}`,
    null,
    id
  );
}
export function postProjectType(data: unknown): Promise<unknown> {
  return execute(
    msalInstance,
    saveFields,
    `${apiConfig.projectTypesEndpoint}`,
    data
  );
}

export function deleteProjectType(id: number): Promise<unknown> {
  return execute(
    msalInstance,
    recycleFields,
    `${apiConfig.projectTypesEndpoint}`,
    null,
    id
  );
}

export function putProjectType(data: unknown): Promise<unknown> {
  return execute(
    msalInstance,
    updateFields,
    `${apiConfig.projectTypesEndpoint}`,
    data
  );
}

export function getRequestMsGraph(queryString: string): Promise<any> {
  return execute(
    msalInstance,
    callMsGraphGet,
    queryString,
    null,
    undefined,
    graphRequest
  );
}

export function postRequestMsGraph(
  queryString: string,
  body: string
): Promise<any> {
  return execute(
    msalInstance,
    callMsGraphPost,
    queryString,
    body,
    undefined,
    graphRequest
  );
}
